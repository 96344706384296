const tabListIncludeAccount = [
  {
    path: '/vendor/vendorMange/store',
    name: '门店管理'
  },

  {
    path: '/vendor/vendorMange/account',
    name: '二级商户号'
  },
  {
    path: '/vendor/vendorMange/reservation',
    name: '预约规则'
  },
  {
    path: '/vendor/vendorMange/role',
    name: '门店角色'
  },
  {
    path: '/vendor/vendorMange/person',
    name: '人员管理'
  }
]
const tabListExcludeAccount = [
  {
    path: '/vendor/vendorMange/store',
    name: '门店管理'
  },
  {
    path: '/vendor/vendorMange/reservation',
    name: '预约规则'
  },
  {
    path: '/vendor/vendorMange/role',
    name: '门店角色'
  },
  {
    path: '/vendor/vendorMange/person',
    name: '人员管理'
  },
  {
    path: '/vendor/vendorMange/collection',
    name: '收款人'
  }
]
export { tabListIncludeAccount, tabListExcludeAccount }

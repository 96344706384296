<route>
{
  "meta": {
    "auth": "storeRoleManage"
  }
}
</route>

<template>
  <div>
    <bm-cardTabList :list="tabList" current="门店角色"></bm-cardTabList>
    <div class="tableContainer">
      <div class="header" style="margin: 18px 0">
        <span class="title">角色列表</span>
        <el-button style="float: right" v-auth="'storeRoleAdd'" type="success" @click="handleAddClick">添加</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column align="center" prop="roleDescribe" label="角色描述"> </el-table-column>
        <el-table-column align="center" label="角色权限">
          <template slot-scope="scope">
            <span v-for="(i, n) in scope.row.rolePrivilegelist" :key="n" class="text"
              ><i class="el-icon-circle-check icon"></i>{{ i.privilegeName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="通知权限">
          <template slot-scope="scope">
            <span v-for="(i, n) in scope.row.informPrivilegelist" :key="n" class="text"
              ><i class="el-icon-circle-check icon"></i>{{ i.privilegeName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-auth="'storeRoleEdit'" type="text" size="small" @click="handleEdit(scope)">编辑</el-button>
            <el-button
              v-auth="'storeRoleDelete'"
              type="text"
              size="small"
              class="deleteBtnText"
              @click="showDelete(scope)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="dialog.edit ? '编辑角色' : '添加角色'"
      :visible.sync="dialog.show"
      width="40%"
      :show-close="false"
    >
      <el-form ref="form" label-position="right" :model="form" :rules="rules" label-width="130px" class="form">
        <el-form-item label="角色昵称" prop="roleName">
          <el-input v-model.trim="form.roleName"></el-input>
        </el-form-item>
        <el-form-item label="拥有所在门店权限">
          <el-checkbox-group class="limit" v-model="form.sysPrivilegeIds">
            <el-checkbox v-for="(item, index) in applyList" :key="index" :label="item.id">{{
              item.privilegeName
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="消息通知">
          <el-checkbox-group class="limit" v-model="form.sysPrivilegeIds">
            <el-checkbox v-for="(item, index) in messageList" :key="index" :label="item.id">{{
              item.privilegeName
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="角色备注" prop="roleDescribe">
          <el-input v-model.trim="form.roleDescribe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn " @click="dialog.show = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="handleAdd">确 定</el-button>
      </span>
    </el-dialog>

    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/businessRole/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { tabListIncludeAccount, tabListExcludeAccount } from '../tabList'
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      rules: [],
      tabList: [],
      form: {
        id: '',
        roleDescribe: '',
        roleName: '',
        businessId: 0,
        rolePrivilegelist: [],
        sysPrivilegeIds: []
      },
      searchValue: {
        page: 1,
        size: 10
      },
      dialog: {
        show: false,
        edit: false
      },
      tableData: [],
      applyList: [],
      messageList: [],
      timestamp: 0
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
    })
  },
  created() {
    this.getRules()
    this.getStorePrivilegeList()
  },
  methods: {
    getRules() {
      this.rules = this.$initRules([
        {
          label: '角色备注',
          value: 'roleDescribe',
          type: 'input',
          required: true
        },
        {
          label: '角色昵称',
          value: 'roleName',
          required: true,
          type: 'input'
        }
      ])
    },
    // 获取角色列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1
      this.searchValue.size = this.pageSize
      this.$api.vendor
        .vendor('getStoreRoleList', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'], ...this.searchValue }
        })
        .then(res => {
          this.tableData = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPrivilege(params) {
      this.$api.vendor
        .vendor('getStorePrivilegeList', params)
        .then(res => {
          if (params === 0) {
            this.applyList = res
          } else {
            this.messageList = res
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    getStorePrivilegeList() {
      // 获取权限列表
      this.getPrivilege(0)
      this.getPrivilege(1)
    },

    // 点击编辑
    handleEdit(scope) {
      this.form = {
        ...this.form,
        ...scope.row,
        sysPrivilegeIds: scope.row.rolePrivilegelist.map(i => i.id).concat(scope.row.informPrivilegelist.map(i => i.id))
      }
      this.dialog = { show: true, edit: true }
    },
    // 点击添加按钮
    handleAddClick() {
      this.dialog = { show: true, edit: false }
      this.form = {
        id: '',
        roleDescribe: '',
        roleName: '',
        businessId: 0,
        rolePrivilegelist: [],
        sysPrivilegeIds: []
      }
    },

    // 添加或修改角色
    handleAdd() {
      this.form.businessId = this.$store.getters['vendor/params/getVendorId']
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        }
        if (this.dialog.edit) {
          const { deleteStatus, informPrivilegelist, tenantId, rolePrivilegelist, ...payload } = this.form
          this.editRoles('putStoreSysRoles', payload)
        } else {
          const { id, rolePrivilegelist, ...payload } = this.form
          this.editRoles('postStoreSysRoles', payload)
        }
      })
    },
    editRoles(url, params) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.vendor
        .vendor(url, params)
        .then(res => {
          this.dialog.show = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
/deep/.el-table .cell {
  text-align: left;
}
.text {
  display: flex;
  white-space: nowrap;
  margin-right: 15px;
  .icon {
    color: #e7141a;
    margin-right: 3px;
    font-size: 15px;
  }
}
.el-input {
  width: 250px;
}

.limit {
  margin-bottom: 20px;
}

.message {
  margin: 10px 0;

  span {
    display: inline-block;
    width: 130px;
  }
}
/deep/.el-dialog {
  border-radius: 20px;
  padding: 20px;
}
.btn {
  border-radius: 30px;
  transition: 0.5s;
}
.outline {
  background: #fff;
  color: #e7141a;
}
</style>
